<template>
  <div>
    <el-page-header
      class="u-bottom-margin--2x"
      :title="$t('handleAdPageBackButtonLabel')"
      :content="$t('createAdPageLabel')"
      @back="goBack"
    ></el-page-header>
    <el-form :model="addform" :rules="rules" ref="addForm" label-width="150px">
      <el-row :gutter="20" class="ads-page__flex-row">
        <el-col :span="11">
          <el-form-item>
            <el-radio-group
              @change="typeChanged($event)"
              v-model="addform.type"
            >
              <!-- <el-radio-button label="popup">{{
                $t("handleAdPagePopupOptionLabel")
              }}</el-radio-button> -->
              <el-radio-button label="banner">{{
                $t("handleAdPageBannerOptionLabel")
              }}</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-radio-group size="mini" v-model="addform.visible">
              <el-radio-button :label="true">{{
                $t("handleAdPageActiveOptionLabel")
              }}</el-radio-button>
              <el-radio-button :label="false">{{
                $t("handleAdPageInActiveOptionLabel")
              }}</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <template v-if="addform.type == 'popup'">
            <el-form-item :label="$t('handleAdPageFieldLabelTimeToOpen')">
              <el-input
                v-model="addform.ad_config.time_to_open"
                :placeholder="$t('handleAdPageFieldLabelTimeToOpen')"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="upload != 'video'"
              :label="$t('handleAdPageFieldLabelTimeToEnd')"
            >
              <el-input
                v-model="addform.ad_config.time_to_end"
                :placeholder="$t('handleAdPageFieldLabelTimeToEnd')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('handleAdPageFieldLabelTimeToClose')">
              <el-input
                v-model="addform.ad_config.time_to_close"
                :placeholder="$t('handleAdPageFieldLabelTimeToClose')"
              ></el-input>
            </el-form-item>
          </template>
          <template v-if="addform.type == 'banner'">
            <el-form-item :label="$t('handleAdPageFieldLabelSearchKeyword')">
              <el-input
                v-model="addform.ad_config.search_keyword"
                :placeholder="$t('handleAdPageFieldLabelSearchKeyword')"
              ></el-input>
            </el-form-item>
          </template>
          <el-form-item
            :label="$t('handleAdPageFieldLabelSelectRegions')"
            size="normal"
          >
            <dropdownCategoriesSelect
              ref="reigonDropdown"
              v-model="addform.regions"
            />
          </el-form-item>

          <el-form-item
            ref="areaDropdown"
            :label="$t('handleAdPageFieldLabelSelectAreas')"
          >
            <dropdownSelectAll
              v-model="addform.areas"
              :source="lookups.areas"
              :placeholder="$t('handleAdPageFieldLabelSelectAreas')"
            >
              <template v-slot:optionBody>
                <el-option
                  v-for="item in lookups.areas"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                >
                </el-option>
              </template>
            </dropdownSelectAll>
          </el-form-item>
          <el-form-item
            prop="context"
            :label="$t('handleAdPageFieldLabelContext')"
            size="normal"
          >
            <div class="u-display-flex">
              <el-select
                :class="
                  $store.state.isRTL
                    ? 'u-left-margin--2x'
                    : 'u-right-margin--2x'
                "
                v-model="addform.context"
                filterable
                @change="contextChanged"
                value-key=""
                :placeholder="$t('handleAdPageFieldLabelContextPlaceholder')"
              >
                <el-option
                  v-for="p in lookups.sections"
                  :key="p.key"
                  :label="p.title"
                  :value="p.key"
                >
                  <span style="float: left">{{ p.key }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    p.title
                  }}</span>
                </el-option>
              </el-select>

              <el-checkbox
                v-if="!(addform.type == 'popup')"
                v-model="addform.Scontext"
                :label="$t('handleAdPageFieldLabelShowInContextButton')"
                @change="showinContextChanged"
                :indeterminate="false"
              >
              </el-checkbox>
            </div>
          </el-form-item>
          <el-form-item
            v-if="isAggregate && addform.type != 'popup'"
            :label="$t('handleAdPageFieldLabelSelectCategories')"
          >
            <dropdownSelectAll
              ref="categoryDropdown"
              key="createAdCategory"
              v-model="addform.categoryId"
              :source="lookups.category"
              :placeholder="$t('handleAdPageFieldLabelSelectCategories')"
              lookupType="category"
            >
              <template v-slot:optionBody>
                <el-option
                  v-for="category in lookups.category"
                  :key="category.key"
                  :label="category.name"
                  :value="category.id"
                >
                </el-option>
              </template>
            </dropdownSelectAll>
          </el-form-item>
          <el-form-item
            :label="$t('handleAdPageFieldLabeleSelectProvider')"
            v-if="addform.type != 'popup' && addform.context && !isAggregate"
          >
            <dropdownSelectAll
              ref="ProviderDropdown"
              :key="`createAdprovider${addform.context}`"
              :source="providersList"
              v-model="addform.providers"
              @change="providerChanged"
              :placeholder="$t('handleAdPageFieldLabeleSelectProvider')"
              lookupType="provider"
            >
              <template v-slot:optionBody>
                <el-option
                  v-for="item in providersList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </template>
            </dropdownSelectAll>
          </el-form-item>
          <el-form-item
            v-if="
              addform.Scontext &&
              addform.context &&
              !isAggregate &&
              (!addform.providers || addform.providers.length < 1)
            "
            :label="$t('handleAdPageFieldLabelNavigateTo')"
          >
            <el-select
              v-model="addform.ad_config.navigate_to_provider"
              :placeholder="$t('handleAdPageFieldLabelNavigateTo')"
              clearable
              filterable
            >
              <el-option
                v-for="item in providersList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="name" :label="$t('handleAdPageFieldLabelName')">
            <el-input
              v-model="addform.name"
              :placeholder="$t('handleAdPageFieldLabelName')"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <div class="btn-group">
            <el-button type="primary" @click="saveForm">{{
              $t("handleAdPageSaveButtonLabel")
            }}</el-button>
          </div>
          <el-radio-group
            v-if="addform.type == 'popup'"
            v-model="upload"
            @change="uploadtypeChanged($event)"
          >
            <el-radio-button label="video">{{
              $t("handleAdPageVideoOptionLabel")
            }}</el-radio-button>
            <el-radio-button label="image">{{
              $t("handleAdPageImageOptionLabel")
            }}</el-radio-button>
          </el-radio-group>

          <el-form-item
            prop="uri"
            class="u-top-margin--2x"
            :label="`${upload} ` + $t('handleAdPageFieldLabelURL')"
            size="normal"
          >
            <el-input
              v-model="addform.uri"
              :placeholder="$t('handleAdPageFieldPlaceholderMediaUrl')"
              size="normal"
              clearable
            ></el-input>
          </el-form-item>
          <div
            v-if="upload == 'image' && addform.uri"
            class="image-display-container"
          >
            <el-image
              class="u-top-margin--2x avatar"
              :src="addform.uri"
              fit="scale-down"
              :lazy="true"
            >
              <div slot="error" class="test">
                <div>
                  <i class="el-icon-picture-outline"> </i>
                </div></div
            ></el-image>
          </div>
          <div v-if="upload == 'video' && addform.uri" class="video-container">
            <video-player
              class="video-player-box"
              ref="videoPlayer"
              :options="{
                // videojs options
                muted: true,
                language: 'en',
                playbackRates: [0.7, 1.0, 1.5, 2.0],
                sources: [
                  {
                    type: 'video/mp4',
                    src: addform.uri,
                  },
                ],
              }"
              :playsinline="true"
              customEventName="customstatechangedeventname"
            >
            </video-player>
          </div>
          <!-- <div class="image-container u-top-margin--2x">
            <el-form-item label="" label-width="0" prop="imageUrl">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholdesdasdr.typicode.com/posts/"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="onChangeImage"
              >
                <img
                  v-if="addform.imageUrl"
                  :src="addform.imageUrl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </div> -->
        </el-col>
      </el-row>
    </el-form>

    <!-- <el-dialog title="Corp Image" :visible.sync="dialogVisible" width="60%">
      <cropper
        classname="cropper"
        :src="addform.imageUrl"
        :stencilProps="{}"
        @change="change"
      ></cropper>
      <el-button @click="dialogVisible = false">Cancel</el-button>
      <el-button type="primary" @click="finishCorping">Confirm</el-button>
    </el-dialog> -->
  </div>
</template>

<script>
// import { Cropper } from "vue-advanced-cropper";
import dropdownCategoriesSelect from "../../components/dropdownCategoriesSelect";
import dropdownSelectAll from "../../components/dropdownSelectAll";
import { mapGetters, mapActions } from "vuex";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import { MarktingService, providersService } from "../../services";
import { utils } from "../../utils/index";
export default {
  components: {
    // Cropper,
    videoPlayer,
    dropdownCategoriesSelect,
    dropdownSelectAll,
  },
  data() {
    return {
      toolbar: false,
      upload: "image",
      providersList: [],
      addform: {
        type: "banner",
        visible: true,
        Scontext: false,
        ad_config: {},
        regions: [],
        providers: [],
        categoryId: [],
      },
      rules: {
        context: [
          {
            required: true,
            message: this.$t("handleAdPageValidationRuleContext"),
            trigger: "blur",
          },
        ],
        uri: [
          {
            required: true,
            message: this.$t("handleAdPageValidationRuleURI"),
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: this.$t("handleAdPageValidationRuleName"),
            trigger: "blur",
          },
        ],
      },
      dialogVisible: false,
    };
  },
  created() {
    this.fetchSectionslookup();
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    isAggregate() {
      const section = this.lookups.sections.filter(
        (item) => item.key == this.addform.context
      )[0];
      if (section) {
        return section.selling_type == "aggregate";
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      fetchSectionslookup: "setSectionslookup",
    }),

    showinContextChanged() {
      if (this.addform.ad_config.navigate_to_provider)
        this.addform.ad_config.navigate_to_provider = undefined;
    },
    finishCorping() {
      this.addform.imageUrl = URL.createObjectURL(this.blob);
      this.dialogVisible = false;
    },
    uploadtypeChanged(value) {
      if (value == "video") {
        console.log(value);
      }
    },
    typeChanged() {
      this.upload = "image";
      this.addform.Scontext = false;
      if (this.addform.type == "popup") {
        this.addform.Scontext = undefined;
        this.addform.providers = [];
        this.addform.categoryId = [];
      }
      this.addform.ad_config = {};
    },
    async getProviders(type = "") {
      try {
        const providersResponse = await providersService.getProviderlookup({
          provider: type,
        });
        let elemDup = utils.arrayEliminateDuplicatesUsingProperty(
          providersResponse,
          "id"
        );
        this.providersList = elemDup;
      } catch (err) {
        console.log("*************************************");
        console.log(err);
      }
    },
    contextChanged(val) {
      if (this.addform.ad_config.navigate_to_provider)
        this.addform.ad_config.navigate_to_provider = undefined;
      if (this.addform.providers) this.addform.providers = [];
      this.getProviders(val);
    },
    change(value) {
      value.canvas.toBlob((blob) => {
        console.log(blob.raw);
        this.blob = blob;
      });
    },
    providerChanged(value) {
      this.addform.providers = value;
      if (this.addform.ad_config.navigate_to_provider)
        this.addform.ad_config.navigate_to_provider = undefined;
    },
    handleChange(e, key) {
      this.addform[key] = parseInt(e.target.value);
    },
    changeval(e, key) {
      this.addform[key] = e.target.value;
    },
    async saveForm() {
      let isValid = await this.$refs["addForm"].validate();
      if (isValid) {
        let pageLoading = this.$loading();
        let adObj = {};
        adObj.uri = this.addform.uri;
        adObj.type = this.addform.type;
        adObj.visible = this.addform.visible;
        adObj.areas = this.addform.areas;
        adObj.regions = this.addform.regions;
        adObj.provider = this.addform.context;
        adObj.ad_config = this.addform.ad_config;
        if (this.upload == "video") {
          adObj.ad_config.playable = true;
          this.addform.ad_config.time_to_end = undefined;
        }
        adObj.context = this.addform.Scontext;

        adObj.categoryId = this.addform.categoryId;
        adObj.providers = this.addform.providers;
        adObj.name = this.addform.name;
        try {
          await MarktingService.ads.createad(adObj);

          this.$notify({
            title: "Success",
            message: this.$t("handleAdPageCreateSuccessMessage"),
            type: "success",
          });

          this.$refs["addForm"].resetFields();
          this.addform.ad_config = {};
          this.addform.areas = [];
          this.addform.regions = [];
        } catch (err) {
          console.log("error");
          console.log(err.response.data.error);
          this.$notify({
            title: this.$t("handleAdPageCreateErrorMessage"),
            message: err.response.data.error,
            type: "error",
          });
        }
        pageLoading.close();
      }
    },
    goBack() {
      this.$router.go(-1);
    },

    // onChangeImage(file) {
    //   let reader = new FileReader();
    //   let isRightResloution = false;
    //   reader.readAsDataURL(file.raw);
    //   reader.onload = (evt) => {
    //     let img = new Image();
    //     img.onload = () => {
    //       isRightResloution =
    //         img.width >= 300 &&
    //         img.width <= 700 &&
    //         img.height >= 300 &&
    //         img.height <= 700;
    //       if (!isJPG) {
    //         this.$message.error("Avatar picture must be JPG format!");
    //         return;
    //       }

    //       if (!isLt2M) {
    //         this.$message.error("Avatar picture size can not exceed 2MB!");
    //         return;
    //       }
    //       if (!isRightResloution) {
    //         this.$message.error(
    //           "Product Image resolution must be in range form  300x300 to 700x700 "
    //         );
    //         return;
    //       }

    //       this.addform.imageUrl = URL.createObjectURL(file.raw);
    //       this.dialogVisible = true;
    //     };
    //     const isJPG = file.raw.type === "image/jpeg";
    //     const isLt2M = file.size / 1024 / 1024 < 2;

    //     img.src = evt.target.result;
    //   };
    // },
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
.btn-group {
  display: flex;
  justify-content: center;
}
// upload image folder
.image-container {
  display: flex;
  justify-content: center;

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px !important;
    text-align: center;
  }
  .avatar {
    width: 355px;
    height: 355px;
    display: block;
  }
}
.image-display-container {
  width: 300px;
  height: 300px;
  margin: 0 auto;
}
::v-deep .test {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 4rem 4rem;
  // width: 140px;
  // height: 140px;
  border: 1px dashed #d9d9d9;
  font-size: 1.5rem;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  &:hover {
    // border-color: #409eff;
  }
}
::v-deep .video-js {
  height: 300px !important;
  width: 600px !important;
}
.cropper {
  height: 500px;
}
</style>
